import React from "react";
// components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../../components/faq/faq-accordion";

// Helpers
import getSortedFaqDataByCategory from "../../../helpers/getSortedFaqDataByCategory";

const MortgageFaq = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Mortgage FAQs"
    }
  ];

  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Home Loans Page"]);

  const seoData = {
    title: "Mortgage FAQs - Top Mortgage Questions Answered",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og: title",
        content: "Mortgage FAQs - Top Mortgage Questions Answered"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Get the answers you need to your top mortgage questions in our robust mortgage FAQs page and find out if a home loan with WaFd Bank is right for you."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/mortgage-faq"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-mortgage-faq-01-250.jpg"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ],
    schema
  };

  const faqAccordionData = {
    id: "mortgage-faq-section",
    pageTitle: "Mortgage FAQs",
    title: null,
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };
  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default MortgageFaq;
